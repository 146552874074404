/*
 * @Author: yuhaiyangz@163.com
 * @Date: 2023-01-31 14:11:01
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-21 16:38:52
 * @Description: App入口文件
 */
import { Route,Routes } from 'react-router-dom'
import CCHeader from './components/header/header'
import CCFooter from './components/footer/footer'
import { FloatingBubble } from 'antd-mobile'
import { UpCircleOutline } from 'antd-mobile-icons'
import { useEffect,Suspense } from 'react'
import { useLocation } from 'react-router-dom'
import routerList from '@/router/index'
import Loading from '@/components/loading/loading'
import NoFound from '@/pages/404/noFind'
import  './APP.scss'
function App() {
  let location = useLocation();
  const goTop = ()=>{
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }
  useEffect(()=>{
    goTop();
  },[location])
  return (
    <>
      <CCHeader/>      
      <Routes>
        {
          routerList.map((item,index)=>{
            return <Route key={index} index path={item.path} element={
              <Suspense fallback={
                <Loading/>
              }>
                <item.component/>
              </Suspense>
            }></Route>
          })
        }
        <Route path="/*" element={<NoFound />} />
      </Routes>
      <CCFooter/>
      <FloatingBubble
        axis='xy'
        magnetic='x'
        style={{
          '--initial-position-bottom': '50px',
          '--initial-position-right': '14px',
          '--edge-distance': '24px',
          '--size':'30px',
          '--z-index':'3'
        }}
        onClick={goTop}
      >
        <UpCircleOutline fontSize={30} />
      </FloatingBubble>
    </>
    
  );
}

export default App;
